<template>
  <div>
      <div class="sctp-card">
        <div class="sctp-card-header">
          <div class="title">确认订单信息</div>
          <div class="fc-red sctp-bold">购买须知：由于技术商品的特殊性，本商品一旦发货，如非卖家原因，不支持退货</div>
        </div>
        <div class="sctp-card-body">
          <el-row class="sctp-pad-tb15 sctp-pad-lr15">
            <el-col :span="10">
              <div class="sctp-tc">店铺宝贝</div>
            </el-col>
            <el-col :span="3">
              <div class="sctp-tc">单价</div>
            </el-col>
            <el-col :span="4">
              <div class="sctp-tc">数量</div>
            </el-col>
            <el-col :span="4">
              <div class="sctp-tc">发货方式</div>
            </el-col>
            <el-col :span="3">
              <div class="sctp-tc">小计</div>
            </el-col>
          </el-row>
          <template v-for="cart in cartGroup">
            <div class="sctp-flex sctp-mar-tb10 sctp-flex-aic">
              <el-image
                class="sctp-mar-r15"
                style="width: 20px; height: 20px"
                :src="cart.shopLogo"
                fit="cover"></el-image>
              <div>店铺：{{ cart.shopName }}</div>
            </div>
            <div class="goods-list">
              <div
                class="sctp-pad-tb15"
                v-for="goods in cart.goods"
                :key="goods.productId">
                <el-row type="flex" align="middle" class=" goods-item">
                  <el-col :span="9">
                    <div class="">
                      <div class="sctp-flex">
                        <el-image
                          class="sctp-mar-r15"
                          style="width: 80px; height: 80px"
                          :src="goods.productLogo"
                          fit="cover"></el-image>
                        <div class="sctp-flex-item-1">
                          <div>
                            {{
                              {2: '源码', 4: '设计', 6: '文档'}[goods.productType]
                            }}编号：#{{ goods.productId }} {{ goods.productName }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="sctp-tc">¥{{ goods.productPrice }}</div>
                  </el-col>
                  <el-col :span="4">
                    <div class="sctp-tc">
                      <el-input-number @change="updateGoodsNumber(arguments,goods)" size="small"
                                       v-model="goods.productAmount"
                                       :step-strictly="true" :min="1" :max="1000"></el-input-number>
                    </div>
                  </el-col>
                  <el-col :span="5">
                    <div class="sctp-color-main sctp-tc">
                      <el-select size="small" v-model="goods.saletype" @change="getSaleTypeStatus()">
                        <el-option
                          v-for="item in goods.saleTypes"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>

                  <el-col :span="3">
                    <div class="sctp-color-main sctp-tc">¥{{
                        goods.productPrice * goods.productAmount
                      }}
                    </div>
                  </el-col>
                </el-row>
                <div v-if="goods.copyrightPrice || goods.copyrightPrice === 0"
                     class="pad-lr15 pad-tb5">
                  <el-checkbox @change="checkServer($event, goods, 'copyrightCheck')">
                    版权费用：{{ goods.copyrightPrice }}￥
                  </el-checkbox>
                </div>
                <div v-if="goods.installPrice || goods.installPrice === 0" class="pad-lr15 pad-tb5">
                  <el-checkbox @change="checkServer($event, goods, 'installCheck')">
                    提供远程安装服务：{{ goods.installPrice }}￥
                  </el-checkbox>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div style="margin: 0 auto;">
          <div class="sctp-card" v-if="addressShow">
            <div class="sctp-card-header">
              <div class="title">确认收获地址</div>
            </div>
            <div class="sctp-card-body">
              <template v-if="!address">
                <div class="sctp-flex sctp-bg-f8 sctp-pad15">
                  请先选择收货地址
                </div>
              </template>
              <template v-else>
                <div class="sctp-flex sctp-bg-f8 sctp-pad15">
                  <span class="sctp-mar-r5">{{ address.receiver }}</span>
                  <span class="sctp-mar-r20">{{ address.call }}</span>
                  <span class="sctp-mar-r5">{{ address.address }}</span>
                  <span class="sctp-mar-r5">{{ address.detailAddress }}</span>
                </div>
              </template>
              <el-button @click="showAddress = true" type="primary" size="small">选择地址</el-button>
              <el-dialog width="70%" title="选择地址" :visible.sync="showAddress">
                <my-address ref="address"></my-address>
                <div slot="footer" class="dialog-footer">
                  <el-button size="small" @click="showAddress = false">取 消</el-button>
                  <el-button size="small" type="primary" @click="chooseAddressConfirm">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        <div class="sctp-flex sctp-flex-fe sctp-flex-aic sctp-pad-tb15 sctp-mar-tb15">
          <div class="sctp-mar-r20">总计：<span>¥{{ totalPrice }}</span></div>
          <el-button @click="submitOrder" type="primary">前往支付</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {address, cart, order} from "@/apis/index";

const config = require('../../resources/js/config')
export default {
  name: 'orderBuy',
  props: ['json'],
  components: {
    myAddress: () => import('@PAGE/users-center-manage/user-manage/user/Address')
  },
  data() {
    return {
      showAddress: false,
      address: null,
      cartGroup: [],
      orders: null,
      addressShow:false
    }
  },
  computed: {
    /**
     * 商品总价格
     * @returns {number}
     */
    totalPrice() {
      let totalPrice = 0
      if (this.cartGroup) {
        this.cartGroup.forEach(cart => {
          if (cart.goods && cart.goods.length > 0) {
            cart.goods.forEach(goods => {
              totalPrice += goods.productPrice * goods.productAmount
              if (goods.installCheck) {
                totalPrice += goods.installPrice;
              }
              if (goods.copyrightCheck) {
                totalPrice += goods.copyrightPrice;
              }
            })
          }
        })
      }
      return totalPrice
    }
  },
  methods: {
    getSaleTypeStatus(){
      let {cartGroup} = this;
      let flag = false
      cartGroup.forEach(res=>{
        res.goods.forEach(item=>{
          if (item.saletype === "4"){
            flag = true
            return
          }
        })
      })
      if (flag){
        this.addressShow = true
      }else {
        this.addressShow = false
      }
        },
    checkServer(flag, target, field) {
      this.$set(target, field, flag);
    },
    submitOrder() {
      let {cartGroup, address} = this;
      // 如果包含物流邮寄的  那么必须选择地址
      if (cartGroup.some(group => {
        return group.goods.some(product => {
          return product.saletype === '4'
        })
      }) && !address) {
        this.$alert('当前商品中包含物流邮寄商品，请选择收货地址！', '温习提示', {});
        return;
      }
      let selectedAddress;
      if (address) {
        selectedAddress = {
          userId: address.userid,
          receiver: address.receiver,
          areaCode: address.addressCode.split(','),
          area: address.address,
          addressDetails: address.detailAddress,
          addressId: address.addressid,
          phone: address.call,
          defaultFlag: address.defaultflag
        }
      }
      let cartIds = [];
      let shops = cartGroup.map(group => {
        return {
          shopId: group.shopId,
          shopName: group.shopName,
          shopLogo: group.shopLogo.substring(group.shopLogo.indexOf('/upload/')),
          products: group.goods.map(product => {
            if (product.id) {
              cartIds.push(product.id);
            }
            return {
              productId: product.productId,
              productName: product.productName,
              productAmount: product.productAmount,
              productPrice: product.productPrice,
              saleType: product.saletype,
              productLogo: product.productLogo.substring(product.productLogo.indexOf('/upload/')),
              productType: {
                2: 'SOURCECODE',
                4: 'DESIGN',
                6: 'DOCUMENT'
              }[product.productType],
              installPrice: product.installCheck && product.installPrice || null,
              copyrightPrice: product.copyrightCheck && product.copyrightPrice || null,
            }
          }),
        }
      });
      order.submitOrder({
        userId: this.user.userId,
        cartIds: cartIds,
        address: selectedAddress,
        shops: shops,
      }).then(res => {
        const {retdata} = res
        if (retdata) {
          this.$Bus.$emit('cartCountChange')
          this.orders = retdata
          this.goPage('/order/pay/' + retdata.map(item => item.orderid).join('-'))
        }
      }).catch(res => {
        if (res && res.retCode) {
          switch (res.retCode) {
            case '9006':
              this.$confirm('当前商品中存在邮件发货商品，您暂未绑定邮箱，请先绑定邮箱！', '温馨提示', {})
              .then(res => {
                if (res === 'confirm') {
                  this.$router.push({
                    path: '/user/userinfo/bindEmail'
                  })
                }
              })
              break;
          }
        }
        return Promise.reject(res);
      })
    },
    chooseAddressConfirm() {
      const addressArray = this.$refs.address.selectionAddress
      const length = addressArray.length
      if (length === 0) {
        this.$message.warning('请选择地址')
        return
      }
      if (length > 1) {
        this.$message.warning('请确认只选择一条地址')
        return
      }
      this.address = addressArray[0]
      this.showAddress = false
    },
    updateGoodsNumber(arr, goods) {
      if (!goods.id) {
        return;
      }
      let [current, old] = arr
      current = Math.round(current || 1)
      if (current !== old) {
        cart.updateGoodsNumber({
          cartId: goods.id,
          amount: current
        }).catch(res => {
          goods.productAmount = old;
        })
      }
    },
    getDefaultAddress() {
      address.getDefaultAddress({
        userId: this.user.userId
      }).then(res => {
        const {retdata} = res
        if (retdata) {
          this.address = retdata
        }
      });
    }
  },
  beforeMount() {
    try {
      let {cartGroup} = this.getQuery();
      if (!cartGroup) {
        this.goPage('/shopping-cart');
        return;
      }
      this.cartGroup = cartGroup;
      this.getDefaultAddress();
    } catch (e) {
      this.goPage('/shopping-cart');
    }
    this.getSaleTypeStatus()
  }
}
</script>
<style scoped lang="scss">
.el-input-number--small {
  width: 120px;
}

.sctp-card {
  padding: 15px;
}

.sctp-card .sctp-card-header .title {
  margin: 15px 0;
  border-left: 3px solid #F90;
  padding-left: 15px;
  color: #F90;
}

.goods-list {
  border: #dedede solid 1px;
  padding: 0 15px;
}

.goods-item + .goods-item {
  border-top: #E7E7E7 solid 1px;
}
</style>
